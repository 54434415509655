import $ from 'bucks-js'
import {parseErrors} from '@/api/ErrorHandler'
import axios from 'axios'

const BOOKING_QUERY = $.urlQueryParam({include: 'patient,address'})

const HEADERS = {
    'Content-type': 'application/json'
}

export class BookingService {

    /**
     * Gets sections and form data.
     * @param formId
     * @return {Promise<unknown>}
     */
    getFormData(productId, formId) {
        return new Promise((resolve, reject) => {
            axios.post(process.env.baseURL + '/booking/v2/form', {
                productId: productId,
                formId: formId
            }, {
                headers: HEADERS
            }).then((response) => {
                if (response && response.data) {
                    resolve(response.data)
                } else {
                    reject(response)
                }
            }).catch((error) => {
                reject(error)
            })
        })
    }


    /**
     * Validates a specific booking section.
     * @param validation
     * @param formId
     * @param data
     * @return {Promise<unknown>}
     */
    validateSection(validation, formId, data) {
        return new Promise((resolve, reject) => {
            axios.post(process.env.baseURL + '/booking/validation/section/' + formId + '/' + validation, data, {
                headers: HEADERS
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                const response = error.response
                if (response && response.status === 400) {
                    reject(response.data)
                } else {
                    reject()
                }
            })
        })
    }


    /**
     * Update existing appointment.
     * @param data
     * @param appointmentID
     * @return {Promise<unknown>}
     */
    updateAppointment(data, appointmentID) {
        return new Promise((resolve, reject) => {
            axios.put(process.env.baseURL + '/booking/appointment/' + appointmentID + BOOKING_QUERY, data, {
                headers: HEADERS
            }).then((response) => {
                this.$store.commit('booking/setIsLoading', false)

                if (response) {
                    this.$store.commit('confirmationBooking/setConfirmationInfo', {
                        date: state.data.datetime,
                        clinic: state.clinic
                    })
                    this.$store.commit('booking/reset')
                }
                resolve()
            }).catch((error) => {
                this.$store.commit('booking/setIsLoading', false)
                const response = error.response
                if (response && response.status === 400) {
                    this.$store.commit('booking/setErrors', parseErrors(response.data))
                }
                reject()
            })
        })
    }

    /**
     * Gets all keys form all pages for booking / store data.
     * @param form
     * @return {unknown[]}
     */
    mapKeys(form) {
        const pages = [...form.pages, form.confirmationPage]
        return pages.flatMap(page => {
            return page.fields.items.flatMap(input => {
                const type = input.fields.type
                if (type === 'address') {
                    const keys = [
                        input.fields.addressKey.fields.key,
                        input.fields.addressOptionalKey.fields.key,
                        input.fields.addressZipCodeKey.fields.key,
                        input.fields.addressCityKey.fields.key,
                        input.fields.addressStateKey.fields.key,
                    ]

                    const addressTypeRequired = input.fields.addressTypeRequired
                    if (addressTypeRequired === true) {
                        return [...keys + input.fields.addressTypeKey.fields.key]
                    } else {
                        return keys
                    }
                } else if (['birthdate', 'consent', 'datetime', 'input', 'selector'].includes(type)) {
                    return [input.fields.key.fields.key]
                } else if (type === 'image-upload') {
                    return input.fields.keys.map(item => item.fields.key)
                } else if (type === 'name') {
                    return [
                        input.fields.firstNameKey.fields.key,
                        input.fields.lastNameKey.fields.key,
                    ]
                } else {
                    return []
                }
            })
        })
    }

}
