
    import $ from 'bucks-js'
    import { mapState, mapGetters } from 'vuex'
    import SamedayHead from '../components/UserInterface/helper/head/SamedayHead'
    import BookingControl from '@/layoutComponents/Booking/mixins/BookingControl'
    import SamedayLayout from '../components/UserInterface/layout/SamedayLayout'
    import NavigationBar from '../layoutComponents/NavigationBar/NavigationBar'
    import BookingStructureProgressBar from '../layoutComponents/Booking/BookingStructureProgressBar'
    import BookingStructureNavigationBar from '../layoutComponents/Booking/BookingStructureNavigationBar'
    import BookingStructurePhotoRight from '../layoutComponents/Booking/BookingStructureRightPhoto'
    import ErrorNotFoundContainer from '@/components/Error/ErrorNotFoundContainer'
    import axios from 'axios'

    export default {
        name: 'BookingLayout',
        middleware: ['publicOrAuthorization'],
        components: {
            NavigationBar, ErrorNotFoundContainer, SamedayLayout, BookingStructureProgressBar,
            BookingStructureNavigationBar, BookingStructurePhotoRight
        },
        mixins: [BookingControl, SamedayHead],
        data: () => ({
            error: false
        }),
        computed: {
            ...mapState({
                clinic: state => state.clinic.clinic,
                bookingPages: state => state.booking.pages,
                hideNavigationBar: state => state.booking.hideNavigationBar,
                disableCanvasColumn: state => state.booking.disableCanvasColumn
            }),
            ...mapGetters({
                currentProgress: 'booking/currentProgress',
            }),
            headData () {
                return { title: this.clinic ? this.clinic.name : null }
            },
            photoUrl () {
                return this.clinic.secondaryPhotoUrl
            },
            sectionPage () { return this.$route.name.indexOf('section') > -1 },
            successPage () { return this.$route.path.indexOf('success') > -1 },
        },
        mounted () {
            if ($.ui.isClient() && (this.sectionPage || this.successPage) && this.bookingPages.length === 0) {
                window.location = window.location.protocol + '//' + window.location.host + '/booking/' + this.$route.params.facility
            }
        },
        // NOTE: fetch() will only be called once throughout booking process
        // When /booking/clinicId is rendered in server, fetch is called and then page is delivered
        // As long as there's no hard refresh, everything happens on client side, and fetch() no longer called.
        async fetch () {
            // if page is /home, load fake background data
            if (this.$route.path === '/home') {
                await this.$store.dispatch('clinic/loadFakeHomeClinic')
                return
            }

            // Else load clinic information--include membership data if applicable
            try {
                await this.$store.dispatch('clinic/fetchClinic', {
                clinicId: this.$route.params.facility
                })
            } catch (error){
                if (error.response?.status === 404){
                    window.location.href = 'https://samedayhealth.com/virtual-care'
                }
            }
        },
        watch: {
            /**
             * Sets booking content div to scroll top 0.
             * @param to
             * @param from
             */
            '$route' (to, from) {
                // scroll left content to top
                if ($.ui.isClient()) {
                    const contentDiv = document.getElementById('booking-content')
                    if (contentDiv) { contentDiv.scrollTop = 0 }
                }
            },
        },
    }
