/*
  Plugin used to load a script that injects Xima Chat into the web application.
*/
export default ({ app }) => {
    !function (f, b, e, v, n, t, s) {
        t = b.createElement(e)
        t.async = true
        t.src = v
        s = b.getElementsByTagName(e)[0]
        s.parentNode.insertBefore(t, s)
    }(window, document, 'script', 'https://chat-widget-loader.ximasoftware.com/chat/9d77f192-eaf4-4fd8-b3c8-81d3fbd8ce12')
};
