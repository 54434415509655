export default {
    headData: {
        site: {
            name: 'Sameday Health',
            description: 'Same-Day COVID-19 Testing In Your Neighborhood. Walk-ups Welcome. Schedule Online Now.',
            photoPath: '<PHOTO_URL>',
            url: 'https://embed.sameday-testing.com',
            color: '#336083',
            locale: 'en',
            faviconPath: '/favicon.ico',
        },

        twitter: {
            name: 'sameday_testing',
        },
    }
}
