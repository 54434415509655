import $ from 'bucks-js'

/**
 * Handles the logged in user.
 */

const USER_INCLUDES = 'address,insurance,patients,patients.address,patients.insurance'
const PUBLIC_RSA_KEY = '-----BEGIN PUBLIC KEY-----\n' +
    'MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA4PKk2yV6qIEJTMDPgFl0\n' +
    '2aU47yP5tYM0ANRX8amM9Q1AItFNH4qK9MN9aAoGB8toXLnLqlDicMyRqY2m7ubD\n' +
    '6qGW046pvNPsN8z2O0J789ZCrPxWziaz7mnJIVRQSesop6pCGdcXT4r4Kyh3et8t\n' +
    'OLU8HgLJq6qQnYw5jLxR9w+/Rs3NfEkvw5FUVIaNEUZIXWw0W/ycZfnhwY98YhDv\n' +
    'O28DMzxWXhW8roVoVbgIyONuWoWkD0poWParIU/iBSeuqV6nu4MyD+jq4LJEg7jk\n' +
    'kEK7zWWeXnwdX2BMGnCDI7J6Y/mu+VsqhOaSpkGq7PkJ2kf0xSw5OupAL2a1vryZ\n' +
    'NW1b0N+8G+Lt56ox5fvLrOrk8uXWu/WQoKccRKAbuscT6e+rPG7Frv930vEn6oxQ\n' +
    '00iPpblAbDE6akF6zC/o2Z/a4hSk72K2B49uu5xmzc9gPQkTx/sjuhiHM2x9SFvn\n' +
    '2DaT9qhvlYcHzAgO6dmRMAhtZ/79H0YpJji0VcAHJ2GMt+PX7eYEoF4Frb4BdjuT\n' +
    'rMhUgRZCrfGVgHT9ewRQvPreUc2aVjR+2ovccfQwjWDsjLUVRZ42l0HamSKRAk4r\n' +
    'L94vwUAYYty4zSqmGR02Vqscb4sNPmxfuEjwJ+PrZsf8mnpLgLsVeEFCOgW7bxeM\n' +
    'YaU4KzW76JoXzGmQoyBcPxUCAwEAAQ==\n' +
    '-----END PUBLIC KEY-----\n'
const LOGIN_REQUEST_CONFIG = { withCredentials: true }

export const state = () => ({
    user: null,
    clientManagerUser: null,
    membership: null,

    /**
     * On dashboard load, Node API called to fetch medical-results & upcoming appointments associated with user email.
     * Since this data is also necessary for Past/Upcoming Visits Pages, store them in state, instead of calling
     *  endpoint repeatedly.
     */
    medicalResults: null,
    upcomingAppointments: null,
    rebookPath: null,
    activePatientIndex: null
})


export const getters = {
    /**
     * Evaluates if user is authenticated and in store.
     * @param state
     * @return {boolean|*}
     */
    authenticated (state) {
        return !!(state.user && state.user.email)
    },

    /**
     * Returns full name of user.
     * @param state
     * @return {string}
     */
    fullName (state) {
        return state.user.firstName + ' ' + state.user.lastName
    },

    /**
     * Determines if at least one user associated with account has a membership package.
     * @param state
     * @returns {boolean}
     */
    accountHasMembership (state) {
        return !!state.user?.membershipPackageId
    },

    /**
     * Show membership link only to accounts that already have one.
     * @param state
     * @returns {boolean}
     */
    showMembershipLink (state) {
        return !!state.user?.membershipPackageId    // identical to acountHasMembership()
    },
}


export const actions = {
    /**
     * Checks if user with associated email has password.
     * @param commit
     * @param email
     * @return {Promise<{}>}
     */
    async passwordExists ({ commit }, { email }) {
        try {
            return await this.$api.post('/auth/password-exists', { email }, LOGIN_REQUEST_CONFIG)
        } catch (error) {
            return error.response
        }
    },

    /**
     * Sends out password forgot mail.
     * @param commit
     * @param email
     * @return {Promise<{}>}
     */
    async passwordForgot ({ commit }, { email }) {
        try {
            return await this.$api.post('/auth/password-forgot', { email })
        } catch (error) {
            return error.response
        }
    },

    /**
     * Sets password for user with associated email.
     * @param commit=
     * @param password
     * @return {Promise<{}>}
     */
    async passwordLogin ({ commit }, { password }) {
        try {
            const query = $.urlQueryParam({ include: USER_INCLUDES })
            const encryptedPassword = this.$encryptData(PUBLIC_RSA_KEY, password)
            const response = await this.$api.post(
                '/auth/password-login' + query,
                { password: encryptedPassword },
                LOGIN_REQUEST_CONFIG
            )
            /* Without 2FA, commit user data to store at this step */
            commit('setUser', response.data.data)
            return response
        } catch (error) {
            return error.response
        }
    },

    /**
     * Sends verification sms for user.
     * @param commit
     * @param index
     * @param code
     * @return {Promise<{}>}
     */
    async sendSMSCode ({ commit }, { index }) {
        try {
            return await this.$api.post('/auth/send-sms-code', { index })
        } catch (error) {
            return error.response
        }
    },


    /**
     * Logs in user into account and puts user into store.
     * @param commit
     * @param code
     * @return {Promise<{}>}
     */
    async codeVerification ({ commit }, { code }) {
        try {
            const query = $.urlQueryParam({ include: USER_INCLUDES })
            const response = await this.$api.post('/auth/verify-code' + query, { code }, LOGIN_REQUEST_CONFIG)
            commit('setUser', response.data.data)
            return response
        } catch (error) {
            return error.response
        }
    },

    /**
     * Sets password for user with associated email.
     * @param commit
     * @param password
     * @param token
     * @return {Promise<{}>}
     */
    async setPassword ({ commit }, { password, token }) {
        try {
            const config = { headers: { Authorization: 'Bearer ' + token } }
            const encryptedPassword = this.$encryptData(PUBLIC_RSA_KEY, password)
            return await this.$api.post('/auth/password-set', { password: encryptedPassword }, config)
        } catch (error) {
            return error.response
        }
    },

    /**
     * Logs in user into account and puts user into store.
     * @param commit
     * @param email
     * @return {Promise<{}>}
     */
    async emailLogin ({ commit }, { email }) {
        try {
            return await this.$api.post('/auth/email', { email })
        } catch (error) {
            return error.response
        }
    },

    /**
     * Sign up user
     * @param commit
     * @param firstName
     * @param lastName
     * @param email
     * @param password
     * @param address
     * @return {Promise<void>}
     */
    async signUp ({ commit }, { firstName, lastName, email, password, address }) {
        try {
            const query = $.urlQueryParam({ include: USER_INCLUDES })
            const encryptedPassword = this.$encryptData(PUBLIC_RSA_KEY, password)
            const response = await this.$api.post('/auth/sign-up' + query, {
                email, firstName, lastName, password: encryptedPassword, address
            }, LOGIN_REQUEST_CONFIG)
            commit('setUser', response.data.data)
            return response
        } catch (error) {
            return error.response
        }
    },

    /**
     * Logs in user into account and puts user into store.
     * @param commit
     * @param birthdate
     * @param token
     * @return {Promise<{}>}
     */
    async birthdateVerification ({ commit }, { birthdate, token }) {
        try {
            const config = {
                headers: { Authorization: 'Bearer ' + token },
                withCredentials: true,
            }
            return await this.$api.post('/auth/birthdate', { birthdate }, config)
        } catch (error) {
            return error.response
        }
    },

    /**
     * Logout user.
     * @param commit
     * @return {Promise<{}>}
     */
    async logout ({ commit }) {
        try {
            const config = { withCredentials: true }
            const response = await this.$api.post('/auth/logout', {}, config)
            commit('setUser', null)
            // need to refactor following lines, after setting up portal store in separate file
            return response
        } catch (error) {
            return error.response
        }
    },

    /**
     * Loads user for JWT token.
     * @param commit
     * @param admin
     * @return {Promise<{}>}
     */
    async me ({ commit }, admin = false) {
        const path = (admin === true) ? '/admin/me' : '/patients/me'
        const query = $.urlQueryParam({ include: USER_INCLUDES })
        try {
            const response = await this.$api.get(path + query)
            commit('setUser', response.data.data)
            return response
        } catch (error) {
            return error.response
        }
    },


    /**
     * Gets a user
     * @param commit
     * @param uuid
     * @return {Promise<{}>}
     */
    async get ({ commit }, { uuid }) {
        try {
            const query = $.urlQueryParam({ include: USER_INCLUDES })
            return await this.$api.get('/user/' + uuid + query)
        } catch (error) {
            return error.response
        }
    },

    /**
     * TODO: how to access state.user.uuid?
     */
    async getMembershipData({ commit }) {
        try {
            const queryParams = $.urlQueryParam({ userId: this.user.uuid })
            const response = await this.$api.get('/patients/membership' + queryParams)
            commit('setMembership', response.data.data)
            return response
        } catch (e) {
            return e.message
        }
    }
}


export const mutations = {
    /**
     * Sets user in state.
     * This User is still from the Elixir code and resembles a Patient
     * @param state
     * @param user
     */
    setUser (state, user) {
        state.user = user
    },

    setClientManagerUser (state, user) {
        state.clientManagerUser = user
    },

    /**
     * Sets membership data relevant to portal page
     * response from GET /patients/membership + query membershipId & userId
     * @param state
     * @param membership
     */
    setMembership (state, membership) {
        state.membership = membership
    }
}
