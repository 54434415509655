export const state = () => ({
    appointmentName: '',
    appointmentLocation: '',
    price: '',
    insurance: false,
    appointmentDateTime: ''
})

export const mutations = {
    SET_APPOINTMENT_DETAILS(state, details) {
        Object.assign(state, details)
    },
    SET_APPOINTMENT_DATE_TIME(state, dateTime) {
        state.appointmentDateTime = dateTime
    },
    SET_PRICE(state, price) {
        state.price = price
    }
}

export const actions = {
    setAppointmentDetails({ commit }, details) {
        commit('SET_APPOINTMENT_DETAILS', details)
    },
    setAppointmentDateTime({ commit }, dateTime) {
        commit('SET_APPOINTMENT_DATE_TIME', dateTime)
    },
    setPrice({ commit }, price) {
        commit('SET_PRICE', price)
    }
}

export const getters = {
    details(state) {
        return state
    }
}

