/**
 * Nuxt.js plugin to extend Google Tag Manager (GTM) configuration.
 */

/**
 * Updates the URL in the browser if it has been modified.
 */
function setUrlIfModified(url) {
    if (window.location.href === url.href) return

    window.history.replaceState({}, document.title, url.href)
}

/**
 * Removes Google Analytics query parameters from the URL.
 * Links containing these query parameters will appear long and unprofessional without this code.
 */
function cleanGAParams() {
    const paramsToRemove = ['_ga', '_gl']

    const url = new URL(window.location.href)

    paramsToRemove.forEach((param) => url.searchParams.delete(param))

    setUrlIfModified(url)
}

/**
 * Callback function for the `hitCallback` parameter in Google Analytics.
 */
async function hitCallback() {
    cleanGAParams()
}

export default function ({ app }) {
    if (!process.client) return

    // Set hitCallback using the vue-analytics package
    app.router.afterEach(() => {
        app.$ga.set('hitCallback', hitCallback)
    })
}
