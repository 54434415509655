
    import { mapGetters, mapState } from 'vuex'
    import SamedayButton from '../../../components/UserInterface/button/SamedayButton'

    export default {
        name: 'PortalSidebar',
        components: { SamedayButton },
        data: () => ({
            links: [
                { title: 'Appointments', path: '/portal/appointments' },
                { title: 'Profile', path: '/portal/account' },
                { title: 'Membership', path: '/portal/membership' },
            ],
            isStaff: false,
        }),
        props: {
            open: { type: Boolean, default: true },
        },
        async mounted () {
            this.isStaff = false
        },
        computed: {
            activePath () {
                return this.$route.path
            },
            ...mapState({
                user: state => state.user.user
            }),
            ...mapGetters({
                showMembershipLink: 'user/showMembershipLink'
            })
        },
    }
